<template>
  <div class="overflow-hidden">
    <Header/>
    <div class="w-full mt-40">
      <g-image alt="Moda Center Santa Cruz" id="cover" class="w-full img-450"/>
      <article class="p-6 mx-4 lg:mx-48 lg:my-10 my-4 border-articles card-shadow">
        <h1 class="title">{{artigo.Titulo}}</h1>
        <p style="white-space: pre-line">{{artigo.Texto}}</p>
      </article>
    </div>
    <Footer/>
  </div>
</template>
<script>
import axios from 'axios'
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";

export default {
  name: "Artigo",
  components: {Footer, Header},
  data () {
    return {
      artigo: []
    }
  },
  async mounted () {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const id = urlParams.get('id');
    try {
      const results = await axios.get(
          'https://blog.aplicativomodacenter.com.br/artigos/'+id
      );
      this.artigo = results.data;

      const image = document.getElementById('cover');
      image.src = "https://blog.aplicativomodacenter.com.br/"+this.artigo.Capa.url;

    } catch (error) {
      console.log(error)
    }
  }
}
</script>
