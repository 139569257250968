<template>
<div>
    <div class="w-full mt-48">
      <img src="https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/Banner%2Fmain_banner.jpg?alt=media&token=eb949e69-cd09-4e4c-8a40-0f99875b68c2" alt="Fábrica da Moda" class="w-full img-450"/>
    </div>
</div>
</template>
<script>
    export default {
        name: "Carousel",
        data () {
            return {
                detaches: []
            }
        }
    }
</script>
