<template>
  <div class="w-full h-auto my-8 flex flex-wrap justify-between px-2 md:px-32">
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Feminina" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Ffeminino.jpg?alt=media&token=ac317722-a3f7-445c-b065-697107095d35)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Feminina</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Feminina</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Masculina" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fmasculino.jpg?alt=media&token=4b2af149-b7c4-4b4e-9d28-b5c29237f688)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Masculina</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Masculina</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Infantil" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Finfantil.jpg?alt=media&token=81c5130c-0feb-4343-878a-34dc3d0c6083)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Infantil</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Infantil</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Jeans%20Wear" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fjeans.jpg?alt=media&token=076ca1de-7c2a-4588-bd7f-bf85f7cdd745)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Jeans<br/>Wear</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Jeans Wear</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Fitness" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Ffitness.jpg?alt=media&token=3e9b1dc4-8c9b-49b6-9fba-daa22ab02ad3)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Fitness</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Fitness</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Roupas%20de%20Dormir" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fpijamas.jpg?alt=media&token=bf3bb27e-40a8-4c8e-a47a-02b157593344)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Roupas de Dormir</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Roupas de Dormir</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Praia" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fpraia.jpg?alt=media&token=bed9f81e-61f3-4507-9d0d-5a940d85d797)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Praia</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Praia</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Plus%20Size" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fplus.jpg?alt=media&token=bcede205-7590-40cd-9e18-7159a6b199c8)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Plus Size</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Plus Size</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20%C3%8Dntima" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fintima.jpg?alt=media&token=f33b041a-ef86-497b-a360-f984e400e95f)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda<br/>Íntima</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Moda Íntima</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Calcados" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fcalcados.jpg?alt=media&token=7dcd847c-41d2-44dd-aef6-067a6de48102)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Calçados</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Calçados</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Bijuterias" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fbijuteria.jpg?alt=media&token=3cc8ae12-35c5-4e96-b633-ab80fe8cd7df)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Bijuterias e Acessórios</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Bijuterias e Acessórios</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Perfumes" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fvariedades.jpg?alt=media&token=cb94dd70-fcb1-4efd-865c-5162f89bb4dc)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Perfumes e Variedades</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Perfumes e Variedades</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Outros" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Festetica.jpg?alt=media&token=cb94dd70-fcb1-4efd-865c-5162f89bb4dc)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Diversos</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Diversos</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Restaurantes" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fcomida.jpg?alt=media&token=aad21abf-5e46-417f-b9d2-0a3989787ee0)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Praça de Alimentação</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Praça de Alimentação</div>
      </div>
    </a>
    <a v-on:click.stop="" href="/servicos" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Fservicos-min.jpg?alt=media&token=fee39b6e-bd59-47ec-9571-6337013db663)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-36 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden md:hidden">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Serviços</div>
      </div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Serviços</div>
      </div>
    </a>
    <a v-on:click.stop=""  href="" class="w-1/2 md:w-1/4 rounded-lg flex flex-col justify-center items-center p-2 hidden md:block">
      <div style="background-image: url(https://firebasestorage.googleapis.com/v0/b/fabrica-da-moda.appspot.com/o/categories%2Ftodos-min.jpg?alt=media&token=8f31a393-aa1f-453e-a8c8-b0db68294b57)"
           class="bg-gray-300 h-64 w-full rounded-lg shadow hover:shadow-2xl bg-cover bg-center"></div>
      <div class="w-64 bg-white -mt-10 shadow-lg rounded-lg overflow-hidden hidden md:block">
        <div class="py-2 text-center font-bold uppercase tracking-wide text-gray-800">Todos</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "Categories"
}
</script>
