<template>
  <div>
    <h3>Redirecionando...</h3>
    <div class="hello">
      {{ msg }} {{android}} {{ios}}
    </div>
  </div>
</template>
<script>
import { isMobile, isAndroid, isIOS } from 'mobile-device-detect';

export default {
  name: 'Download',
  metaInfo: {
    title: 'Baixar Apps'
  },
  data () {
    return {
      msg: isMobile ? 'Smartphone' : 'Desktop',
      android:  isAndroid ? 'Android' : '',
      ios: isIOS? 'IOS' : ''
    }
  },
  mounted: function () {
    if (isMobile){
      if (isAndroid){
        location.href = "https://play.google.com/store/apps/details?id=info.vras.fbricadamoda";
      } else if (isIOS){
        location.href = "https://apps.apple.com/br/app/fábrica-da-moda/id1539774609";
      } else {
        location.href = "https://fabricadamoda.com";
      }
    } else {
      location.href = "https://fabricadamoda.com";
    }
  }
}
</script>
