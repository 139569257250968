<template>
  <div class="px-6 py-4 fixed right-0 right--60 z-50 bg-green-500 rounded-l-full shadow-lg cursor-pointer transition-all transition-ease duration-200 btn-zap">
    <a href="" class="flex items-center link-zap" target="_blank">
      <img src="@/assets/icones/zap-logo.png" alt="" class="w-12">
      <p class="ml-2 text-lg font-medium text-white">Fale conosco pelo Whatapp</p>
    </a>
  </div>
</template>

<script>
  export default {
    name: "BtnWhatsapp",
    methods: {
      whatsapp: function() {
        const btnZap = document.querySelector('.btn-zap');
        btnZap.addEventListener("mouseover", () => {
              btnZap.classList.remove('right--60')
        });
        btnZap.addEventListener("mouseout", () => {
          if (getComputedStyle(btnZap).right === '0px') {
              btnZap.classList.add('right--60')
            }
        })
      }
    },
    mounted() {
      this.whatsapp();
    }
  }
</script>