<template>
  <div class="bg-moda">
    <div class="banner-lgpd banner-lgpd__container">
      <div class="banner-lgpd__column">
        <p>
          Nossos sites utilizam cookies para melhorar a sua segurança e experiência online. Ao continuar, você concorda com as condições.
        </p>
      </div>
      <div class="banner-lgpd__column">
        <button class="banner-lgpd__accept">OK</button>
      </div>
    </div>
    <section class="instagram flex flex-wrap mx-auto center content-center shadow">
      <ul id="instagram" class="flex flex-wrap mx-auto">
        <li v-for="(edge, index) in images" :key="index" class="text-center w-1/2 sm:w-1/2 md:w-1/4 lg:flex-1 xl:flex-1 inline-block">
          <a href="https://instagram.com/caruarufabricadamoda" target="_blank"><img class="inline-block instagram-img" :src=edge /></a>
        </li>
      </ul>
    </section>

    <div class="px-8 md:px-32 pt-8 bg-azul-ideal block md:flex w-full text-white" id="footer__scroll">
      <section class="w-full md:w-1/3 right justify-end institucional  block md:hidden mb-12">
        <h3 class="text-white institucional">Institucional</h3>
        <ul>
          <li class="my-2"><a v-on:click.stop="" href="/sobre">Sobre o Fábrica da Moda</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/estabelecimentos">Estabelecimentos</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/servicos">Serviços</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/novidades">Novidades</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/download">Baixe Nosso Aplicativo:</a></li>
            <li class="w-40 h-10 relative center my-2">
              <a href="https://play.google.com/store/apps/details?id=info.vras.fbricadamoda" target="_blank" title="Aplicativo Fábrica da Moda Android">
                <img src="@/assets/googleplay.png" alt="Baixar Android" class="m-auto"/>
              </a>
            </li>
            <li class="w-40 h-10 relative center my-2">
              <a href="https://apps.apple.com/br/app/fábrica-da-moda/id1539774609" target="_blank" title="Aplicativo Fábrica da Moda iOS">
                <img src="@/assets/apple.png" alt="Baixar IOS" class="w-11/12 m-auto mt-8"/>
              </a>
            </li>
        </ul>
      </section>
      <section class="w-full md:w-1/3">
        <div class="phone flex">
          <img src="@/assets/phone.png" alt="Telefone Fábrica da Moda" width="50" class="mr-4 mt-4" style="width: 50px;height: 50px;"/>
          <div class="block">
            <p class="text-white" style="line-height:1em">Central de Atendimento ao cliente</p>
            <h3 class="text-white" ><a href="tel:30458151">(81) 3045-8151</a></h3>
            <h3 class="text-white" ><a href="tel:30455456">(81) 3045-5456</a></h3>
            <p class="text-white" style="line-height:1em">Segunda das 7h às 18h<br/>Terça a sexta, das 8h às 18h<br/>Sábado, das 8h às15h.</p>
          </div>
        </div>
        <img src="@/assets/logo.png" alt="Logo da Produtos Ideal"
             class="my-12 bottom-0 ml-16 hidden md:block">
      </section>
      <section class="w-full md:w-1/3 mt-16 md:mt-0">
        <div class="redes block md:flex center content-center">
          <div class="flex content-center center m-auto">
            <a href="https://www.facebook.com/caruarufabricadamoda" target="_blank" class="m-auto md:mr-2 md:inline-block">
              <img src="@/assets/facebook.png" class="icons-social"  alt="Instagram Fábrica da Moda" style="height: 48px;max-width: 48px;"/>
            </a>
            <a href="https://www.instagram.com/caruarufabricadamoda/" target="_blank" class="m-auto md:ml-2 md:mr-4 md:inline-block">
              <img src="@/assets/instagram.png" class="icons-social" alt="Instagram Fábrica da Moda" style="height: 48px;max-width:48px"/>
            </a>
          </div>
          <p class="text-white justify-center center content-center mt-8 md:mt-2 text-center md:text-left" style="line-height:1em">Siga-nos nas nossas redes sociais e acompanhe todas nossas novidades.</p>
        </div>
        <a href="https://goo.gl/maps/RKjnbpBiQ4o1QPVL7" target="_blank" class="endereco block md:flex mt-12 text-left">
          <img src="@/assets/pin.png" alt="Endereço" class="block md:inline md:mr-4 m-auto mb-4 md:mb-auto" width="38px" style="height: 50px"/>
          <div class="inline-block">
            <h4 class="text-white is-bold text-center lg:text-left" style="font-weight: 700; font-size:0.95em">Av. Lourival José da Silva Nº 80, Pétropolis</h4>
            <p class="text-white  text-center md:text-left">Caruaru - PE</p>
            <p class="text-blue-700  text-center md:text-left">ver no mapa</p>
          </div>
        </a>
      </section>
      <section class="w-full block md:hidden content-center mt-16">
        <img src="@/assets/logo.png" alt="Logo da Fábrica da Moda"
             class="m-auto w-auto">
      </section>
      <section class="w-full md:w-1/3 right justify-end institucional mt-8 md:mt-0  hidden md:block">
        <h3 class="text-white institucional">Institucional</h3>
        <ul>
          <li class="my-2"><a v-on:click.stop="" href="/sobre">Sobre o Fábrica da Moda</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/estabelecimentos">Estabelecimentos</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/servicos">Serviços</a></li>
          <li class="my-2"><a v-on:click.stop="" href="/novidades">Novidades</a></li>
        </ul>
        <h3 class="text-white institucional"><a v-on:click.stop="" href="/download">Baixe Nosso Aplicativo:</a></h3>
        <ul class="mt-2 flex right float-right">
          <li class="w-32 h-10 relative" style="left: -8px;">
            <a href="https://play.google.com/store/apps/details?id=info.vras.fbricadamoda" target="_blank" title="Aplicativo Android">
              <img src="@/assets/googleplay.png" alt="Baixar Android"/>
            </a>
          </li>
          <li class="w-32 h-10 mt-2">
            <a href="https://apps.apple.com/br/app/fábrica-da-moda/id1539774609" target="_blank" title="Aplicativo iOS">
              <img src="@/assets/apple.png" alt="Baixar IOS" class="w-11/12"/>
            </a>
          </li>
        </ul>
      </section>
    </div>
    <div class="copyrigth py-0 md:py-2 right-0 px-8 md:px-32 bg-white">
      <div class="block md:flex">
        <p class="text-sm w-full md:w-1/2 py-2 flex center justify-center md:justify-start">Copyright {{this.year}} &copy; Todos os direitos reservados.</p>
        <p class="text-sm w-full md:w-1/2 py-2 flex center justify-center md:justify-end">Site desenvolvido pela <a href="https://vras.info" target="_blank"><img src="@/assets/vras.png"  class="scale-0 hover:scale-150 ml-2"  alt="Vras"/></a></p>
      </div>
    </div>
  </div>
</template>
<script>
import BackToTop from 'vue-backtotop';

export default {
  name: "Footer",
  // eslint-disable-next-line vue/no-unused-components
  components: { BackToTop },
  data () {
    return {
      images: [],
      year: ''
    }
  },
  async mounted(){
    var date = new Date()
    this.year =  date.getFullYear()
    if (localStorage.cookies) {
      document.getElementsByClassName("banner-lgpd")[0].classList.add("accept");
    }

    var acceptCookies = function () {
      document.getElementsByClassName("banner-lgpd")[0].classList.add("accept");
      localStorage.setItem("cookies", "accept");
    };

    var btnCookies = document.getElementsByClassName("banner-lgpd__accept")[0];
    btnCookies.onclick = acceptCookies;

    // try {
    //   const userInfoSource = await axios.get('https://www.instagram.com/caruarufabricadamoda/')
    //   // HTML recebido
    //   const jsonObject = userInfoSource.data.match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1].slice(0, -1)
    //   const userInfo = JSON.parse(jsonObject)
    //   // Recupera os 10 primeiros resultados
    //   const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 8)
    //   for (let media of mediaArray) {
    //     const node = media.node;
    //     // Processar apenas se houver uma imagem
    //     // if ((node.__typename && node.__typename !== 'GraphImage')) {
    //     //   continue
    //     // }
    //     // Insere thumbnail src no array
    //     this.images.push(node.thumbnail_src)
    //   }
    // } catch (e) {
    //   console.error('Não foi possível recuperar as fotos. Motivo: ' + e.toString())
    // }
  }
}
</script>
