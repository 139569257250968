import credentials from "./credentials";
import firebase from 'firebase/app';

let firebaseInstance;

export const getFirebase = () => {
    if (firebaseInstance) {
        return firebaseInstance
    }
    firebase.initializeApp(credentials.config);
    firebaseInstance = firebase;

    return firebase
};
