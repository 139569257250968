import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from "@/views/Home";
import Sobre from "@/views/Sobre";
import Servicos from "@/views/Servicos";
import Download from "@/views/Download";
import Estabelecimentos from "@/views/Estabelecimentos";
import Marca from "@/views/Marca";
import Novidades from "@/views/Novidades";
import Artigo from "@/views/Artigo";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sobre',
    name: 'Sobre',
    component: Sobre
  },
  {
    path: '/servicos',
    name: 'Servicos',
    component: Servicos
  },
  {
    path: '/estabelecimentos',
    name: 'Estabelecimentos',
    component: Estabelecimentos
  },
  {
    path: '/marca',
    name: 'Marca',
    component: Marca
  },
  {
    path: '/novidades',
    name: 'Novidades',
    component: Novidades
  },
  {
    path: '/artigo',
    name: 'Artigo',
    component: Artigo
  },
  {
    path: '/download',
    name: 'Download',
    component: Download
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
