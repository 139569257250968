<template>
  <div class="overflow-hidden">
    <Header/>
    <div class="w-full mt-40">
      <g-image src="@/assets/banner2.jpg" alt="Fábrica da Moda" class="w-full img-450"/>
      <article class="mx-8 py-8 px-4 lg:mx-32 lg:my-12 my-4 border-articles card-shadow">
        <h1 class="title mt-8">Fábrica da Moda</h1>
        <img src="@/assets/fabrica.jpeg" alt="Fabrica da moda">
        <p class="text-left">O Empreendimento Fábrica da Moda foi idealizado por Lenilson Torres, em meados de 2001, que vislumbrou em uma área antes vazia um nicho de mercado. O potencial devia-se à sua proximidade com o Parque 18 de maio. As obras do empreendimento iniciaram em 2003 e sua inauguração foi em março de 2006.</p>
        <br/>
        <p class="text-left">Com localização estratégica, o Fábrica da Moda fica em frente à Feira de Artesanato e bem ao lado do Parque 18 de Maio, o maior complexo têxtil de Pernambuco. Hoje é âncora nesse contexto, atingindo um público de aproximadamente um milhão e meio de habitantes, que migram de cerca de 40 municípios da região para consumir aqui.</p>
        <br/>
        <p class="text-left">Inicialmente a missão do empreendimento era abraçar os feirantes da sulanca, com o objetivo de oferecer aos clientes e feirantes maior comodidade, estrutura e organização nos dias de feira. Ao passar do tempo o Fábrica da Moda tornou-se um centro de compras, com comércio de atacado e varejo.</p>
        <br/>
        <p class="text-left">A estrutura do empreendimento conta com cerca 16.000m² de área construída, que abriga um mix de 180 lojas (232 box no total), uma praça de alimentação, caixas eletrônicos, lotérica e estacionamento com capacidade para 450 automóveis.</p>
        <br/>
        <p class="text-left">O Fábrica da Moda tem como meta proporcionar aos clientes conforto, comodidade, segurança e as melhores opções de compras no centro de Caruaru, reunindo em um único complexo de compras as necessidades dos mais variados públicos.</p>
        <br/><br/><br/>
        <h2 class="title text-center">Missão</h2>
        <p class="text-center">Proporcionar aos clientes as melhores opções de compras no tocante a preço, qualidade, conforto, segurança e praticidade.</p>
        <br/><br/><br/>
        <h2 class="title text-center">Visão</h2>
        <p class="text-center">Ser o Centro de Compras consolidado, oferecendo comodidade, segurança e lazer para os clientes e visitantes.</p>
        <div class="center inline-block">
          <img src="@/assets/1.jpg" class="w-1/3 m-auto inline-block mx-1" alt="Fabrica da moda">
          <img src="@/assets/2.jpg"  class="w-1/3 m-auto inline-block mx-1" alt="Fabrica da moda">
          <img src="@/assets/3.jpg" class="w-1/3 m-auto inline-block mx-1" alt="Fabrica da moda">
          <img src="@/assets/4.jpg" class="w-1/3 m-auto inline-block mx-1" alt="Fabrica da moda">
        </div>
      </article>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";

export default {
  metaInfo: {
    title: 'Sobre'
  },
  components: {
    Header,
    Footer
  }
}
</script>
