<template>
  <div class="sidebar" style="z-index: 10000">
    <div class="sidebar-backdrop" @click="closeSidebarPanel" v-if="isPanelOpen"></div>
    <transition name="slide">
      <div v-if="isPanelOpen"
           class="sidebar-panel">
        <img src="@/assets/logo.png" alt="Logo Fábrica da Moda" class="w-auto m-auto"/>
        <hr class="mt-4 mb-8">
        <slot></slot>
        <div class="absolute bottom-0 mb-24">
          <hr/>
          <h3><a v-on:click.stop="" href="/download">Baixe Nosso Aplicativo:</a></h3>
          <ul class="mt-2 flex">
            <li class="w-32 h-10 relative" style="left: -8px;">
              <a href="https://play.google.com/store/apps/details?id=info.vras.fbricadamoda" title="Aplicativo Android" target="_blank">
                <img src="@/assets/googleplay.png" alt="Baixar Android"/>
              </a>
            </li>
            <li class="w-32 h-10 mt-2">
              <a href="https://apps.apple.com/br/app/fábrica-da-moda/id1539774609" title="Aplicativo iOS" target="_blank">
                <img src="@/assets/apple.png" alt="Baixar IOS" class="w-11/12"/>
              </a>
            </li>
          </ul>
          <br/>
          <h3>Siga nossas redes sociais:</h3>
          <ul class="flex mt-2 center content-center align-middle">
            <li class="w-auto m-auto">
              <a href="https://www.facebook.com/caruarufabricadamoda" target="_blank">
                <img src="@/assets/iconfb.png" alt="Facebook Fábrica da Moda" class="m-auto"/>
              </a>
            </li>
            <li class="w-auto m-auto">
              <a href="https://www.instagram.com/caruarufabricadamoda/" target="_blank">
                <img src="@/assets/iconinsta.png" alt="Instagram Fábrica da Moda" class="m-auto"/>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { store, mutations } from '@/store.js';

export default {
  name: "Sidebar",
  methods: {
    closeSidebarPanel: mutations.toggleNav
  },
  computed: {
    isPanelOpen() {
      return store.isNavOpen
    }
  }
}
</script>
