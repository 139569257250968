<template>
  <div class="overflow-hidden">
    <Header/>
    <div class="w-full mt-40">
      <Carousel/>
    </div>
    <Categories/>
    <Footer/>
  </div>
</template>

<script>
import Header from  '../components/Header/Header';
import Footer from  '../components/Footer/Footer';
import Categories from "../templates/Categories";
import Carousel from "../templates/Carousel";

export default {
  name: "Home",
  metaInfo: {
    title: "Fábrica da Moda",
    description: 'No coração de Caruaru.'
  },
  components:{
    Header,
    Footer,
    Categories,
    Carousel
  }
}
</script>
