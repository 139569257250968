<template>
  <div class="overflow-hidden">
    <Header/>
    <div class="w-full mt-48 px-8 md:px-32 relative">
      <img src="@/assets/banner2.jpg" alt="Fábrica da Moda" class="w-full img-450"/>
      <section class="w-full relative py-1 mt-8 inline-block md:flex itens">
        <article class="w-full md:w-1/3 md:mb-4 md:pr-4 my-2 p-2 shadow hover:shadow-lg relative" v-for="article in articles.slice(0,3)" :key="article.id" v-bind:class="[ article.id % 2 === 1 ? 'mx-4' : '']">
<!--          <a :href="'/artigo?id='+article.id"  v-on:click.stop="">-->
<!--            <figure class="relative hover">-->
<!--              <g-image :src="'https://blog.aplicativomodacenter.com.br'+article.Capa.url" alt="Roupa em destaque" class="w-full object-cover"/>-->
<!--              <figcaption class="px-2 py-3 relative bg-white text-xs font-light">-->
<!--                <p class="text-sm font-bold text-black">{{ article.Titulo }}</p>-->
<!--                <p class="mt-2 text-xs font-regular text-gray-600">{{ article.Resumo }}</p>-->
<!--              </figcaption>-->
<!--            </figure>-->
<!--            <a :href="'/artigo?id='+article.id"  v-on:click.stop="" class="ler-mais text-sm p-1 text-gray-800">Ler mais</a>-->
<!--          </a>-->
        </article>
      </section>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
// import axios from 'axios';
export default {
  name: "Novidades",
  components: {
    Header,
    Footer
  },
  data () {
    return {
      articles: []
    }
  },
  async mounted () {
    // try {
    //   const results = await axios.get('https://blog.aplicativomodacenter.com.br/artigos');
    //   this.articles = results.data.reverse();
    // } catch (error) {
    //   console.log(error);
    // }
  }
}
</script>
