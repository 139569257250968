export default {
    config: {
        apiKey: "AIzaSyD3pwXjIGKGnb8ixrn1Wwe5tC3z-MdNOYw",
        authDomain: "fabrica-da-moda.firebaseapp.com",
        databaseURL: "https://fabrica-da-moda.firebaseio.com",
        projectId: "fabrica-da-moda",
        storageBucket: "fabrica-da-moda.appspot.com",
        messagingSenderId: "506954284807",
        appId: "1:506954284807:web:612549ccf14f59e2ee4fa2",
        measurementId: "G-5E76338GMM"
    }
}
