<template>
  <div class="overflow-hidden">
    <Header/>
    <img src="" alt="Capa da marca" id="cover" ref="cover" class="w-full mt-48 object-cover object-center h-32 md:h-64"/>
    <div class="px-4">
      <aside class="w-full center justify-center">
        <img src="" alt="Logo da marca" id="logo" ref="logo" class="rounded-full border-4 object-center object-cover border-white h-32 w-32 m-auto"/>
        <h1 class="text-md font-bold uppercase" id="name" ref="name"></h1>
        <p class="text-xs" id="category" ref="category"></p>
        <p class="text-xs" id="address" ref="address"></p>
        <p class="text-xs" id="phone" ref="phone"></p>
        <p class="text-xs" id="phone2" ref="phone2"></p>
        <btn-whatsapp/>
        <div class="redes w-full justify-center text-center center pt-8">

          <g-image src="~/assets/email.png" id="email-img" alt="email" class="w-12 h-12 m-auto"/>
          <p class="text-xs mt-4" id="email" ref="email"></p>

          <g-image src="~/assets/iconinsta.png" id="insta-img" alt="instagram" class="w-12 h-12 m-auto"/>
          <p class="text-xs mt-4" ref="insta" id="insta"></p>
        </div>
      </aside>
      <section id="instaProfile" class="w-full px-8 md:px-24 pb-16 mx-auto center content-center"></section>
    </div>
    <Footer/>
  </div>
</template>
<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";
import BtnWhatsapp from "../components/BtnWhatsapp";
import {getFirebase} from '@/db';
import axios from "axios";

export default {
  name: "Marca",
  components: {Footer, Header, BtnWhatsapp},
  async mounted() {
    var instaProfile = '';
    const lazyStorage = import('firebase/storage');
    const lazyDb = import('firebase/database');
    const lazyApp = import('firebase/app');

    await Promise.all([ lazyApp,lazyDb, lazyStorage]).then(([firebase]) => {
      getFirebase(firebase);
      const ref = firebase.database().ref("Stores");
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const id = urlParams.get('id');
      const storage = firebase.storage();
      console.log(ref.child(id));
      ref.child(id).once('value').then(function(data) {
        console.log(data.val().name);
        document.getElementById("name").innerText = data.val().name;
        document.getElementById("phone").innerText = data.val().phone;
        const loja = data.val().loja;
        const categories = data.val().categories;

        for (const category in categories){
          const cat = document.createElement("span");
          cat.innerHTML = "<b>"+categories[category] + " / </b>";
          document.getElementById("category").appendChild(cat);
        }

        const p = document.createElement("p");
        p.innerText = "Fábrica da Moda, Loja " + loja;
        document.getElementById("address").appendChild(p);

        if (data.val().phone2 != null) {
          document.getElementById("phone2").innerText = data.val().phone2;
        }
        if (data.val().email != null) {
          const link = document.createElement("a");
          link.href = "mailto:" + data.val().email;
          link.target = "_blank";
          link.innerText = data.val().email;
          document.getElementById("email").appendChild(link)
        } else {
          document.getElementById("email-img").hidden = true;
        }

        if (data.val().instagram != null) {
          const link = document.createElement("a");
          link.href = "https://www.instagram.com/" + data.val().instagram;
          link.target = "_blank";
          link.innerText = "@" + data.val().instagram;
          document.getElementById("insta").appendChild(link);
          instaProfile = data.val().instagram;
        } else {
          document.getElementById("insta-img").hidden = true;
        }

        let link;
        const linkZap = document.querySelector('.link-zap');
        switch (data.val().zap) {
          case 1:
            link = document.createElement("a");
            // eslint-disable-next-line no-case-declarations
            let phone = data.val().phone.replace("(", "");
            phone = phone.replace(")", "");
            phone = phone.replace("-","");
            phone = phone.replace(/\s+/,"");
            link.href = "https://api.whatsapp.com/send?phone=55"+phone+"&text=Mensagem enviada pelo Aplicativo Moda Center.%0a%0a%0a%0a%0a Digite aqui sua mensagem: ";
            link.innerText = "Fale pelo Whatsapp";
            linkZap.href = link.href;
            link.target = "_blank";
            break;
          case 2:
            link = document.createElement("a");
            // eslint-disable-next-line no-case-declarations
            let phone2 = data.val().phone2.replace("(","");
            phone2 = phone2.replace(")", "");
            phone2 = phone2.replace("-","");
            phone2 = phone2.replace(/\s+/,"");
            link.href = "https://api.whatsapp.com/send?phone=55"+phone2+"&text=Mensagem enviada pelo Aplicativo Moda Center.%0a%0a%0a%0a%0a Digite aqui sua mensagem: ";
            link.innerText = "Fale pelo Whatsapp";
            linkZap.href = link.href;
            link.target = "_blank";
            break;
          default:
            document.getElementById("zap-img").hidden =  true;
            document.querySelector('.btn-zap').hidden = true;
        }

        const path = data.val().path;
        const coverRef = storage.refFromURL(path+"/cover-min.jpg");
        const logoRef = storage.refFromURL(path+"/logo-min.jpg");
        coverRef.getDownloadURL().then(function(url) {
          document.getElementById("cover").src = url;
        });
        logoRef.getDownloadURL().then(function(url) {
          document.getElementById("logo").src = url;
        });
      }).catch(error =>{
        console.log(error);
      }).then(function (){
        getInstagram(instaProfile);
      });
    }).catch(error => {
      console.log(error);
    });

    async function getInstagram(instaProfile){
      try {
        const userInfoSource = await axios.get('https://www.instagram.com/'+instaProfile);
        const jsonObject = userInfoSource.data.match(/<script type="text\/javascript">window\._sharedData = (.*)<\/script>/)[1].slice(0, -1)
        const userInfo = JSON.parse(jsonObject)

        const mediaArray = userInfo.entry_data.ProfilePage[0].graphql.user.edge_owner_to_timeline_media.edges.splice(0, 12)
        const section = document.getElementById("instaProfile");
        for (let media of mediaArray) {
          const node = media.node;
          const div = document.createElement('div');
          div.className += 'text-center w-1/2 sm:w-1/2 md:w-1/4 lg:flex-1 xl:flex-1 inline-block';
          section.appendChild(div);

          const image = document.createElement('img');
          image.className += 'inline-block m-2 shadow-md object-cover';
          image.src = node.thumbnail_src;

          div.appendChild(image);
        }
      } catch (e) {
        console.error('Não foi possível recuperar as fotos. Motivo: ' + e.toString())
      }
    }
  },
}
</script>
