<template>
    <div class="w-full fixed z-10">
        <header class="w-full flex bg-moda text-black px-8 lg:px-32 h-16 md:justify-between items-center" style="height: 100px">
          <a href="/" class="bg-white px-6 pt-16 text-center align-middle lg:relative absolute left-0 -ml-4" style="width: 200px; height: 200px; border-radius: 50%;">
            <img alt="Logo Fábrica da Moda" src="@/assets/logo.png" class="m-auto center align-middle"/>
          </a>
          <Burger class="mx-8 md:mx-32"></Burger>
        </header>
        <Sidebar>
          <ul class="ml-auto block justify-around">
            <li class="my-2 py-2 border-b"><a v-on:click.stop="" href="/sobre">Sobre o Fábrica da Moda <i class="icon absolute right-0 mr-6"> > </i></a></li>
            <li class="my-2 py-2 border-b"><a v-on:click.stop="" href="/estabelecimentos">Estabelecimentos <i class="icon absolute right-0 mr-6"> > </i></a></li>
            <li class="my-2 py-2 border-b"><a v-on:click.stop="" href="/servicos">Serviços <i class="icon absolute right-0 mr-6"> > </i></a></li>
            <li class="my-2 py-2 border-b"><a v-on:click.stop="" href="/novidades">Novidades <i class="icon absolute right-0 mr-6"> > </i></a></li>
          </ul>
        </Sidebar>
        <section class="flex h-8 px-4 mb-8 h-24 xl:px-24 itens overflow-x-scroll bg-white shadow-md text-center align-middle">
            <article v-for="(menu, index) of categorias" :key="index" class="m-4 mb-8 text-center align-middle relative max-h-12 max-w-12">
                <a :href="menu.link" v-on:click.stop="" class="categories-link">
                    <div class="h-12 w-12 rounded-lg bg-categorias mx-auto p-2 flex justify-center items-center  shadow-2xl">
                        <img :src="menu.icone" alt="Ícone de" class="max-h-full">
                    </div>
                    <p class="uppercase whitespace-no-wrap categoria-nav text-center align-middle w-full" style="font-size: 0.75em">{{ menu.moda }}</p>
                </a>
            </article>
        </section>
    </div>
</template>

<script>
    import Burger from "../Burger";
    import Sidebar from "../Sidebar";
    import algoliasearch from 'algoliasearch/lite';
    import instantsearch from 'instantsearch.js';

    export default {
      components: {Sidebar, Burger},
      data: () => {
            return {
                categorias: [
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Feminina',moda:'Feminino', icone: require('@/assets/icones/feminino.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Masculina', moda:'Masculino', icone: require('@/assets/icones/masculino.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Infantil', moda:'Infantil', icone: require('@/assets/icones/infantil.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Jeans%20Wear',moda:'Jeans', icone: require('@/assets/icones/jeans.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Fitness', moda:'Fitness', icone: require('@/assets/icones/fitness.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Praia',moda:'Praia', icone: require('@/assets/icones/praia.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Roupas%20de%20Dormir',moda:'Sleepwear', icone: require('@/assets/icones/sleep.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20%C3%8Dntima',moda:'Íntima', icone: require('@/assets/icones/intimo.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Moda%20Plus%20Size',moda:'Plus Size', icone: require('@/assets/icones/plus.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Calcados',moda:'Calçados', icone: require('@/assets/icones/shoes.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Perfumes',moda:'Perfumes', icone: require('@/assets/icones/perfumes.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Bijuterias',moda:'Bijuterias', icone: require('@/assets/icones/diversos.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Restaurantes',moda:'Comida', icone: require('@/assets/icones/restaurante.png')},
                  {link: '/estabelecimentos?refinementList%5Bcategories%5D%5B0%5D=Outros',moda:'Diversos', icone: require('@/assets/icones/impressao.png')},
                  {link: '/estabelecimentos',moda:'Todas', icone: require('@/assets/icones/more.png')}
                ]
            }
        },
        methods: {
            estilo: function() {
                const nav = document.getElementsByClassName('categoria-nav');

                for (let menu of nav) {
                    // let navWidth = menu.offsetWidth;
                    menu.style.position = 'absolute';
                }
            },
            search: function(){
                var query = document.getElementById("query").value;
                if (query.length > 2){
                    location.href = "/estabelecimentos?query=" + query;
                } else {
                    location.href = "/estabelecimentos";
                }
            }
        },
        mounted() {
            this.estilo();

          const searchClient = algoliasearch('VN4NJCGX1T', '943821ce7fce0034a06a8d933def5254');

          instantsearch({
            indexName: 'Stores',
            searchClient,
            routing: true
          });

            var input = document.getElementById("query");
            var category = document.getElementsByClassName("categories-link");
            var link = location.href;
            category[0].addEventListener("click", function(){
               if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[1].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[2].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[3].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[4].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[5].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[6].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[7].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[8].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[9].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[10].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[11].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[12].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[13].addEventListener("click", function(){
               if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
             category[14].addEventListener("click", function(){
                if(link.includes("/estabelecimentos")){
                    window.location.reload();
                }
            });
            input.addEventListener("keyup", function(event) {
                if (event.keyCode === 13) {
                    event.preventDefault();
                    var query = document.getElementById("query").value;
                    if (query.length > 2){
                        location.href = "/estabelecimentos?query=" + query;
                    } else {
                        location.href = "/estabelecimentos";
                    }
                }
            });
        }
    };
</script>
