<template>
  <div class="overflow-hidden">
    <Header/>
    <div class="w-full mt-48 px-8 md:px-32 relative">
      <h1 class="pl-8 mb-12 text-2xl relative">Nossos Serviços</h1>
      <div class="pb-12 relative content-center justify-center items-center">
        <section v-for="(item, index) in itens" :key="index"
                 class="bg-gray-200 w-1-2 md:w-1/3 inline-block p-1 md:p-4 mx-1 md:m-2 z-10 border-radius-8 shadow hover:shadow-2xl center text-center">

          <img v-bind:src="require(`@/assets/${item.image}`)" v-bind:alt="item.nomeProduto" class="m-auto">

          <article>
            <h3 class="font-bold">{{ item.nomeProduto }}</h3>
            <p class="leading-tight mb-8">{{ item.descricao }}</p>
          </article>

        </section>
      </div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Header from "@/components/Header/Header";
import Footer from "@/components/Footer/Footer";

export default {
  name: "Serviços",
  components: {
    Header,
    Footer
  },
  data: () => {
    return {
      itens: [
        {
          image: 'printer.png',
          nomeProduto: 'Gráfica Rápida',
          descricao: '',
        },
        {
          image: 'loterica.png',
          nomeProduto: 'Lotérica',
          descricao: '',
        },
        {
          image: 'wifi.png',
          nomeProduto: 'Wifi',
          descricao: '',
        },
        {
          image: 'atm.png',
          nomeProduto: 'Banco 24horas',
          descricao: '',
        },
        {
          image: 'camera.png',
          nomeProduto: 'Segurança',
          descricao: '',
        },
        {
          image: 'car.png',
          nomeProduto: 'Estacionamento',
          descricao: '',
        }
      ]
    }
  }
}
</script>
